// import React, { useState } from "react";

// const Artists = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     profession: "",
//     photo: null,
//     video: null,
//     socialMedia: "",
//   });

//   const handleChange = (e) => {
//     const { name, value, type, files } = e.target;
//     if (type === "file") {
//       setFormData({
//         ...formData,
//         [name]: files[0],
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //   // Send formData to the backend for processing

//   //   // Reset the form after submission
//   //   setFormData({
//   //     name: "",
//   //     email: "",
//   //     mobile: "",
//   //     profession: "",
//   //     photo: null,
//   //     video: null,
//   //     socialMedia: "",
//   //   });
//   // };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       // Create a FormData object to send the file uploads
//       const formData = new FormData();
//       formData.append("name", formData.name);
//       formData.append("email", formData.email);
//       formData.append("mobile", formData.mobile);
//       formData.append("profession", formData.profession);
//       formData.append("photo", formData.photo);
//       formData.append("video", formData.video);
//       formData.append("socialMedia", formData.socialMedia);

//       // Send the form data to the backend using the fetch API
//       const response = await fetch("http://localhost:5000/api/artist-registration", { // Replace with your backend API URL
//         method: "POST",
//         body: formData,
//       });

//       if (response.ok) {
//         // Reset the form after successful submission
//         setFormData({
//           name: "",
//           email: "",
//           mobile: "",
//           profession: "",
//           photo: null,
//           video: null,
//           socialMedia: "",
//         });
//         alert("Artist registration successful!");
//       } else {
//         alert("Artist registration failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       alert("An error occurred. Please try again later.");
//     }
//   };

//   return (
// <div className="min-h-[1200px] pt-[142px] w-screen bg-gray-100">
//     <div className="min-h-screen flex items-center justify-center ">
//       <div className="bg-white p-8 rounded shadow-md w-full sm:w-96">
//         <h2 className="text-2xl font-semibold mb-6">Artist Registration</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="mb-4">
//             <label className="block text-gray-600">Name:</label>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleChange}
//               required
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-600">Email:</label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-600">Mobile:</label>
//             <input
//               type="tel"
//               name="mobile"
//               value={formData.mobile}
//               onChange={handleChange}
//               required
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-600">Profession:</label>
//             <select
//               name="profession"
//               value={formData.profession}
//               onChange={handleChange}
//               required
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             >
//               <option value="">Select Profession</option>
//               <option value="actor">Actor</option>
//               <option value="singer">Singer</option>
//               {/* Add more profession options */}
//             </select>
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-600">Photo:</label>
//             <input
//               type="file"
//               name="photo"
//               accept="image/*"
//               onChange={handleChange}
//               required
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-600">Video:</label>
//             <input
//               type="file"
//               name="video"
//               accept="video/*"
//               onChange={handleChange}
//               required
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             />
//           </div>
//           <div className="mb-4">
//             <label className="block text-gray-600">Social Media:</label>
//             <input
//               type="text"
//               name="socialMedia"
//               value={formData.socialMedia}
//               onChange={handleChange}
//               className="w-full border rounded px-3 py-2 focus:outline-none focus:border-blue-500"
//             />
//           </div>
//           <div>
//             <button
//               type="submit"
//               className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
//             >
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default Artists;

import React from 'react';

const Artists = () => {
  const iframeStyle = {
    width: '640px', // Adjust the width as needed
    border: '0',
    margin: '0',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Box shadow
    margin: '0 auto', // Center alignment
  };

  return (
    <div style={{ paddingTop: '132px' }} className='h-screen my-5'>
      <iframe className='h-[400px]'
        src="https://docs.google.com/forms/d/e/1FAIpQLSen9su1gOD7z9Hy-ML_Xm7mB0lyYfkO1gIJ0hjaGRuuZOPk3w/viewform?embedded=true"
        style={iframeStyle}
        title="Embedded Google Form"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default Artists;

